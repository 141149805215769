<template>
  <div class="events-content">
    <div v-for="(t, k) in types" :key="k">
      <div
        class="events"
        :class="[{ active: dados.filter(i => i === t.text).length }, t.text]"
        @click.prevent="setTypes(t.text)"
      >
        <div class="img flex justify-center"><img :src="t.img" /></div>
        <div class="title">{{ t.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['dados'],
  data: () => ({
    types: []
  }),
  methods: {
    setTypes(type) {
      this.$emit('type', type)
    }
  },
  mounted() {
    this.types = [
      {
        title: this.$t('locais.type.comunitario'),
        img: require('../assets/eventos/geral.png'),
        text: 'comunitario'
      },
      {
        title: this.$t('locais.type.raid'),
        img: require('../assets/eventos/raid.png'),
        text: 'raid'
      },
      {
        title: this.$t('locais.type.horaRaid'),
        img: require('../assets/eventos/raid.png'),
        text: 'horaRaid'
      },
      {
        title: this.$t('locais.type.horaDestaque'),
        img: require('../assets/eventos/horaDestaque.png'),
        text: 'horaDestaque'
      },
      {
        title: this.$t('locais.type.liga'),
        img: require('../assets/eventos/liga.png'),
        text: 'liga'
      },
      {
        title: this.$t('locais.type.geral'),
        img: require('../assets/eventos/geral.png'),
        text: 'geral'
      },

    ]
  }
}
</script>

<style lang="scss" scoped>
.events-content {
  margin-bottom: 25px;
  display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: row;
    gap: 3px;

  .events {
    display: flex;
    widows: 100%;
    flex-direction: column;
    background-color: #e8e8e8;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    margin: 2px;
    border: 2px solid;
    box-sizing: border-box;
    cursor: pointer;
    border: 2px solid #ccc;

    .img {
      img {
        height: 30px;
      }
    }
    .title {
      color: #000;
      font-size: 12px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.comunitario {
  &.active {
    background-color: #673bb7;
    border: 2px solid #673bb7;
    .title {
      color: #fff;
    }
  }
}
.raid {
  &.active {
    background-color: #0063d9;
    border: 2px solid #0063d9;
    .title {
      color: #fff;
    }
  }
}
.horaRaid {
  &.active {
    background-color: #007fe3;
    border: 2px solid #007fe3;
    .title {
      color: #fff;
    }
  }
}
.horaDestaque {
  &.active {
    background-color: #0094d6;
    border: 2px solid #0094d6;
    .title {
      color: #fff;
    }
  }
}
.liga {
  &.active {
    background-color: #00a5b9;
    border: 2px solid #00a5b9;
    .title {
      color: #fff;
    }
  }
}
.geral {
  &.active {
    background-color: #00b296;
    border: 2px solid #00b296;
    .title {
      color: #fff;
    }
  }
}
</style>
